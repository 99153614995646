import { Gallery } from '@tovia/man-protos/dist/types/content.types';
import { useEffect } from 'react';
import { useSuccessPageQueryParams } from 'src/client/pages/SuccessPage/Hooks/useSuccessPageQueryParams';
import { ApiClient, useAppData } from 'src/client/helpers';
import { apiUrls } from 'src/client/helpers/constants';
import { sendPurchaseGaData } from 'src/client/helpers/sendPurchaseGaData';
import { OneClickVars } from 'src/client/pages/SuccessPage/SuccessPage';

export type UseHandlePurchaseValidationArgs = {
  setGallery: (gallery: Gallery) => void;
  setOneClickVars: React.Dispatch<React.SetStateAction<OneClickVars>>;
  setSuccess: (success: boolean) => void;
  setValidating: (validating: boolean) => void;
  validating: boolean;
};

export const useHandlePurchaseValidation = ({
  setGallery,
  setOneClickVars,
  setSuccess,
  setValidating,
  validating,
}: UseHandlePurchaseValidationArgs) => {
  const { networkSites, testBillers, testId, testPlatform, promotions } = useAppData();
  const { affiliateID, cartItemUUID, extSubscriptionID, galleryUUID, quality, mamPartnerID, extProductID } =
    useSuccessPageQueryParams();

  // This whole setup is to give time to the backend to process the invoice data that we get from a postback
  useEffect(() => {
    if (validating) {
      const api = new ApiClient();
      setTimeout(async () => {
        try {
          const {
            body: { gallery: galleryItem, invoices },
          } = await api.get(apiUrls.get.invoice, {
            params: {
              cartItemUUID,
              extSubscriptionID,
              galleryUUID,
              testPlatform,
            },
          });

          let mamID: string;
          let affiliateCode: string | undefined;

          if (mamPartnerID) {
            affiliateCode = `mam-${mamPartnerID}`;
            mamID = mamPartnerID;
          }

          if (affiliateID) {
            affiliateCode = affiliateID;
          }

          if (galleryItem) {
            setGallery(galleryItem);
          }

          setOneClickVars(
            (prevVars: OneClickVars): OneClickVars => ({
              ...prevVars,
              billerId: invoices[0].billerID,
              extSubscriptionID: extSubscriptionID ? extSubscriptionID : undefined,
              extProductID: extProductID ? extProductID : undefined,
              subscriptionUUID: invoices[0].subscriptionUUID,
              mamID,
              PA: affiliateCode,
            }),
          );

          sendPurchaseGaData({
            affiliateCode,
            cartItemUUID,
            galleryUUID,
            galleryItem,
            invoices,
            networkSites,
            promotions,
            quality,
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Failed to process invoice data', error);
        }

        setSuccess(true);
        setValidating(false);
      }, 5000);
    }
  }, [
    affiliateID,
    cartItemUUID,
    extProductID,
    extSubscriptionID,
    galleryUUID,
    mamPartnerID,
    networkSites,
    promotions,
    promotions.promoCode,
    quality,
    setGallery,
    setOneClickVars,
    setSuccess,
    setValidating,
    testBillers,
    testId,
    testPlatform,
    validating,
  ]);
};
