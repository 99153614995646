// Typed version of Object.entries.
// See the following for more info and caveats:
// https://github.com/microsoft/TypeScript/issues/35101

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

type EntriesFn = <T extends object>(obj: T | undefined) => Entries<T>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const entries: EntriesFn = (obj) => Object.entries(obj ?? {}) as any;
