import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { Helmet } from 'react-helmet';
import { Gallery } from '@tovia/man-protos/dist/types/content.types';
import { useHandleSuccessfulPayment } from 'src/client/pages/SuccessPage/Hooks/useHandleSuccessfulPayment';
import { useHandlePurchaseResponse } from 'src/client/pages/SuccessPage/Hooks/useHandlePurchaseResponse';
import { useCreateOneClickURL } from 'src/client/pages/SuccessPage/Hooks/useCreateOneClickURL';
import { useSuccessPageQueryParams } from 'src/client/pages/SuccessPage/Hooks/useSuccessPageQueryParams';
import { useHandlePurchaseValidation } from 'src/client/pages/SuccessPage/Hooks/useHandlePurchaseValidation';
import { useAppData } from '../../helpers';

export type OneClickVars = {
  billerId?: number;
  extSubscriptionID?: string;
  extProductID?: string;
  mamID?: string;
  subscriptionUUID?: string;
  PA?: string;
};

export function SuccessPage() {
  const { cdnUrl } = useAppData();
  const { purchaseType } = useSuccessPageQueryParams();

  const [success, setSuccess] = useState<boolean>(false);
  const [validating, setValidating] = useState<boolean>(false);
  const [gallery, setGallery] = useState<Gallery>();
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);
  const [oneClickVars, setOneClickVars] = useState<OneClickVars>({
    billerId: undefined,
    extSubscriptionID: undefined,
    mamID: undefined,
    subscriptionUUID: undefined,
    PA: undefined,
  });

  // If the purchase was rejected we redirect the user to the declined page, if not we set the validating to true
  useHandlePurchaseResponse({ setValidating, setSuccess });

  // If we have the invoice validated and the google analytics event sent, we set the success to true
  useHandlePurchaseValidation({ setGallery, setOneClickVars, setSuccess, setValidating, validating });

  // This hook handles the path after the purchase was successful
  useHandleSuccessfulPayment({ success, gallery, iframeLoaded, billerId: oneClickVars.billerId });

  // For the backend to generate the url needed to register a user as oneClick
  const oneClickUrlData = {
    billerID: oneClickVars.billerId,
    extSubscriptionID: oneClickVars.extSubscriptionID,
    extProductID: oneClickVars.extProductID,
    PA: oneClickVars.PA,
    subscriptionUUID: oneClickVars.subscriptionUUID,
  };

  // We ask for the oneClick URL from cams-api, if we have the data we create the iframe
  // and load the url, kicking in the oneClick registration process
  const iframeURL = useCreateOneClickURL({ userBillingData: oneClickUrlData });

  return (
    <SuccessPageWrapper>
      <Helmet>
        <title>Thank You For Your Purchase!</title>
      </Helmet>
      <SuccessWrapper>
        {success && (
          <>
            <SuccessImage src={`${cdnUrl}/success/success_checkmark.svg`} alt="success" />
            <SuccessText>You&apos;re all set!</SuccessText>
            <LoadingText>
              {purchaseType === 'pps' ? 'Thank you for your gallery purchase' : 'Loading your account...'}
            </LoadingText>
            {iframeURL && <StyledIframe src={iframeURL} title="oneClickURL" onLoad={() => setIframeLoaded(true)} />}
          </>
        )}
        {validating && (
          <>
            <SpinnerIcon icon={faSpinnerThird} />
            <LoadingText>Please wait while we validate and setup your purchase...</LoadingText>
          </>
        )}
      </SuccessWrapper>
    </SuccessPageWrapper>
  );
}

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const animation = keyframes`
  0% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.3);
  }

  45% {
    transform: scale(1);
  }

  60% {
    transform: scale(1.15);
  }

  75% {
    transform: scale(1);
  }

  90% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
`;

const SuccessPageWrapper = styled.div`
  align-items: center;
  background-color: #eff0f2;
  display: flex;
  font-family: 'Open Sans', sans-serif;
  height: 100vh;
`;

const SuccessWrapper = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
`;

const SuccessImage = styled.img`
  animation: ${animation} 1s forwards;
  animation-delay: 0.15s;
`;

const SuccessText = styled.h1`
  font-size: 2.25rem;
  font-weight: 700;
  margin: 12px 0;
`;

const LoadingText = styled.p`
  color: #999;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
`;

const SpinnerIcon = styled(FontAwesomeIcon)`
  width: 64px;
  height: 64px;
  animation: ${spin} 1s infinite linear;
`;

const StyledIframe = styled.iframe`
  width: 1px;
  height: 1px;
  border: none;
`;
