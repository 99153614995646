import React, { MouseEventHandler, useMemo } from 'react';
import styled from 'styled-components/macro';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AltPayment } from '@tovia/man-protos/dist/types/billing.types';
import { useCookies } from 'react-cookie';
import { FormattedMessage } from 'react-intl';
import { Placeholder } from 'src/client/components/Placeholder/Placeholder';
import { PlaceholderImage } from 'src/client/components/Placeholder/PlaceholderImage';
import { Selectable } from 'src/client/components/TemplateA/Selectable/Selectable';
import { PlaceholderLine } from 'src/client/components/Placeholder/PlaceholderLine';
import { useAppData } from 'src/client/helpers/useAppData';
import { Biller } from 'src/shared/constants/billers';
import { JoinPageProduct } from 'src/shared/types';
import { makeUrlSafeName } from './makeUrlSafeName';
import { SectionTitle } from './styles';

type PaymentPrice = {
  billerID: number;
  paymentType: string;
  subscriptionPlans: JoinPageProduct[];
};

type Props = {
  cdnUrl?: string;
  className?: string;
  paymentOption?: AltPayment;
  previousBillerID: number;
  result?: PaymentPrice[];
  selectedAltPayment?: AltPayment;
  selectPrimaryPayment: (type?: number) => void;
  setPaymentOption: (paymentOption?: AltPayment) => void;
  setShowAlternativePaymentPanel: React.Dispatch<React.SetStateAction<boolean>>;
  showAlternativePaymentPanel?: boolean;
};

export function PaymentChoice(props: Props) {
  const {
    cdnUrl,
    className,
    selectPrimaryPayment,
    result = [],
    paymentOption,
    previousBillerID,
    selectedAltPayment,
    showAlternativePaymentPanel,
  } = props;

  const enableAlternativePayments = useAppData().site?.enableAlternativePayments;
  const [cookies] = useCookies(['mam', `${useAppData().site?.abbreviation}_PA`]);

  const affiliateID = cookies[`${useAppData().site?.abbreviation}_PA`];
  const mam = cookies['mam'];

  const loadingPaymentOptions = result.length === 0;
  const selectedAltPaymentName = selectedAltPayment?.name;

  const primaryPayments = useMemo(
    () =>
      result.reduce((payments, payment) => {
        switch (payment.paymentType) {
          // Priority for CC Processors
          // Rocketgate => Epoch => CCBill
          case 'Credit Card':
            // If there's nothing set, return what we got.
            if (!payments[0]) {
              payments[0] = payment;
              return payments;
            }

            if (payment.billerID === Biller.RocketGate && previousBillerID === Biller.RocketGate) {
              payments[0] = payment;
              return payments;
            }

            if (payment.billerID === Biller.CCBill && previousBillerID === Biller.CCBill) {
              payments[0] = payment;
              return payments;
            }

            return payments;

          // Priority for PayPal
          // Notes for the future because I'm talking about it with Shaun now, and no
          // one will remember except this code
          // (if we add SegPay, its probably first) => Epoch
          case 'PayPal':
            payments[1] = payment;
            return payments;

          default:
            return payments;
        }
      }, [] as PaymentPrice[]),

    // () => result.filter((payment) => ['Credit Card', 'PayPal'].includes(payment.paymentType)),
    [result, previousBillerID],
  );

  const showAlternativePayments = () => {
    if (result.length === 0) return;
    if (props.selectedAltPayment) {
      props.setPaymentOption(undefined);
    }
    props.setShowAlternativePaymentPanel((value) => !value);
  };

  const altPaymentButtonActive = showAlternativePaymentPanel || (!!selectedAltPaymentName && !paymentOption);

  return (
    <PaymentChoiceContainer className={className}>
      <SectionTitle>
        <span>2.</span>
        <FormattedMessage id="payment.choice.title" />
      </SectionTitle>
      <div className="payments">
        <PaymentOptionPlaceholders loadingPaymentOptions={loadingPaymentOptions} />
        {primaryPayments.map((payment) => {
          const { paymentType, billerID } = payment;
          const sprite = makeUrlSafeName(paymentType);
          const active = !showAlternativePaymentPanel && paymentOption?.billerID === billerID;

          if (paymentType === 'Credit Card')
            return (
              <CreditCardPayment
                className={`biller-${payment.billerID}`}
                key={billerID}
                cdnUrl={cdnUrl}
                onClick={() => selectPrimaryPayment(billerID)}
                active={active}
              />
            );

          return (
            <div key={billerID} id={sprite} className="payment-method">
              <Payment onClick={() => selectPrimaryPayment(billerID)} active={active}>
                <img src={`${cdnUrl}/payment/${sprite}.svg`} alt={paymentOption?.name} />
              </Payment>
            </div>
          );
        })}
      </div>

      {!affiliateID && !mam && enableAlternativePayments && (
        <Payment className="alternative-payments" onClick={showAlternativePayments} active={altPaymentButtonActive}>
          {loadingPaymentOptions ? (
            <Placeholder>
              <PlaceholderLine />
            </Placeholder>
          ) : (
            <>
              <AltPaymentTitle>
                {selectedAltPaymentName || <FormattedMessage id="payment.choice.alternative_payments" />}
              </AltPaymentTitle>
              <Icon icon={faEllipsisV} />
            </>
          )}
        </Payment>
      )}
    </PaymentChoiceContainer>
  );
}

const PaymentOptionPlaceholders = (props: { loadingPaymentOptions: boolean }) => {
  if (!props.loadingPaymentOptions) return null;
  return (
    <>
      {[...new Array(2)].map((_, index) => (
        <div key={index} className="payment-method">
          <PaymentPlaceholder key={index}>
            <Placeholder>
              <PlaceholderImage />
            </Placeholder>
          </PaymentPlaceholder>
        </div>
      ))}
    </>
  );
};

const CreditCardPayment = (props: {
  cdnUrl?: string;
  onClick: MouseEventHandler<HTMLDivElement>;
  active: boolean;
  className?: string;
}) => {
  return (
    <div id="credit_card" className={`payment-method ${props.className ?? ''}`}>
      <Payment onClick={props.onClick} active={props.active}>
        <CreditCardsWrapper>
          <img src={`${props.cdnUrl}/payment/small/visa.svg`} alt="VISA" />
          <img src={`${props.cdnUrl}/payment/small/mastercard.svg`} alt="MasterCard" />
          <img src={`${props.cdnUrl}/payment/small/discover.svg`} alt="Discover" />
          <img src={`${props.cdnUrl}/payment/small/jcb.svg`} alt="JCB" />
        </CreditCardsWrapper>
      </Payment>
    </div>
  );
};

const CreditCardsWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  max-height: 26px;
  overflow: hidden;
`;

const PaymentChoiceContainer = styled.div`
  & .payments {
    display: flex;
    justify-content: stretch;
    gap: 20px;
  }

  & .payment-method {
    flex: 1 1 calc(50% - 10px);
    overflow: hidden;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 150%;
  margin-left: auto;
  margin-right: 10px;
`;

const AltPaymentTitle = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  font-size: 14px;
`;

const Payment = styled(Selectable)`
  padding: 0 20px;
  margin: 0;
  user-select: none;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color: #666;
  font-size: 1.1rem;
  height: 48px;

  &.active {
    border-color: dodgerblue;

    img {
      filter: saturate(100%);
    }
  }

  img {
    vertical-align: middle;
    filter: saturate(0%);
  }

  &.alternative-payments {
    margin-top: 10px;
  }
`;

const PaymentPlaceholder = styled(Payment)`
  width: 100%;

  ${PlaceholderImage} {
    width: 120px;
    padding-top: 26px;
    margin: 0 auto;
  }

  & .line.text {
    margin: 8px auto 0;
  }
`;
