import type { Gallery } from '@tovia/man-protos/dist/types/content.types';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { useSuccessPageQueryParams } from 'src/client/pages/SuccessPage/Hooks/useSuccessPageQueryParams';
import { useAnalyticsABMeasurement } from 'src/client/components/Analytics/useAnalyticsABMeasurement';
import { useAnalyticsSignals } from 'src/client/components/Analytics/useAnalyticsSignals';
import { useAppData } from 'src/client/helpers';
import { BILLING_TEST_ORDER_COOKIE, SITE_TEST_ORDER_COOKIE, routerPaths } from 'src/client/helpers/constants';
import { getConversionTypeByPurchase } from 'src/client/helpers/utils';
import { ABTestDefinitions } from 'src/shared/constants/abTests';
import { MeasurementTypes } from 'src/shared/types/userMeasurement';
import { Biller } from 'src/shared/constants/billers';

type UseHandleSuccessfulPaymentArgs = {
  success: boolean;
  iframeLoaded?: boolean;
  gallery?: Gallery;
  billerId?: number;
};

export const useHandleSuccessfulPayment = (args: UseHandleSuccessfulPaymentArgs): void => {
  const { signalAnalyticsNewSubscription } = useAnalyticsSignals();
  const { sendABTestMeasurement } = useAnalyticsABMeasurement({
    abTestId: ABTestDefinitions.PreJoinPageRedesign.id,
  });
  const { cartItemUUID, extSubscriptionID, galleryUUID, paymentType, purchaseType } = useSuccessPageQueryParams();
  const { success, gallery, iframeLoaded, billerId } = args;
  const { site } = useAppData();
  const history = useHistory();
  const [, , removeCookie] = useCookies([BILLING_TEST_ORDER_COOKIE, SITE_TEST_ORDER_COOKIE]);

  useEffect(() => {
    if (success) {
      if (purchaseType !== 'pps' && !galleryUUID) {
        const salesData =
          cartItemUUID || extSubscriptionID
            ? {
                cartItemUUID: cartItemUUID || undefined,
                extSubscriptionID: extSubscriptionID || undefined,
              }
            : undefined;

        signalAnalyticsNewSubscription(salesData);

        const conversionType = getConversionTypeByPurchase({ purchaseType, paymentType });
        sendABTestMeasurement({
          abTestId: ABTestDefinitions.PreJoinPageRedesign.id,
          type: MeasurementTypes.Conversion,
          conversionType,
        });
      }

      removeCookie(BILLING_TEST_ORDER_COOKIE, {
        domain: `.${site.domain}`,
        path: '/',
      });
      removeCookie(SITE_TEST_ORDER_COOKIE, {
        domain: `.${site.domain}`,
        path: '/',
      });

      const url = routerPaths.purchase.upsales;
      const billerNeedsIframe = billerId === Biller.Epoch || billerId === Biller.CCBill;

      if (billerNeedsIframe && iframeLoaded) {
        if (purchaseType === 'pps' || gallery?.path) {
          const galleryRedirect = !!gallery?.path;

          if (galleryRedirect) {
            window.location.assign(`https://${site.domain}${gallery?.path}`);
            return;
          }
        }

        if (purchaseType !== 'cc') {
          window.location.assign(`https://${site.domain}`);
          return;
        }

        history.push(url);
      } else if (!billerNeedsIframe) {
        setTimeout(() => {
          if (purchaseType === 'pps' || gallery?.path) {
            const galleryRedirect = !!gallery?.path;

            if (galleryRedirect) {
              window.location.assign(`https://${site.domain}${gallery?.path}`);
              return;
            }
          }

          if (purchaseType !== 'cc') {
            window.location.assign(`https://${site.domain}`);
            return;
          }

          history.push(url);
        }, 5000);
      }
    }
  }, [
    cartItemUUID,
    extSubscriptionID,
    galleryUUID,
    gallery,
    history,
    purchaseType,
    removeCookie,
    site.domain,
    success,
    signalAnalyticsNewSubscription,
    sendABTestMeasurement,
    paymentType,
    iframeLoaded,
    billerId,
  ]);
};
