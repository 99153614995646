import React from 'react';
import styled from 'styled-components/macro';
import { DESKTOP } from 'src/client/helpers/mediaQueries';
import { useAppData } from 'src/client/helpers';

export const HeroSection = () => {
  const { cdnUrl } = useAppData();

  return (
    <Wrapper cdnUrl={cdnUrl}>
      <Headline>Try For FREE</Headline>
      <SubHeadline>Any Premium Site</SubHeadline>
    </Wrapper>
  );
};

const Wrapper = styled.section<{ cdnUrl: string }>`
  height: 272px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${(p) => p.cdnUrl}/members-portal/man_portal_hero_desktop@2x.jpg);
  background-size: cover;
  margin-bottom: 10px;
`;

const Headline = styled.h1`
  color: #fff;
  font-size: 52px;
  margin: 0;

  @media ${DESKTOP} {
    font-size: 60px;
  }
`;

const SubHeadline = styled.h2`
  color: #ceab58;
  font-size: 23px;
  margin: 0;
  font-weight: normal;
`;
