import { ABTestDefinition, ABTestNames } from '../types/ABTests';
import { UserTypes } from './userTypes';
import { CacheLevel } from '../../server/helpers/cacheLayer';
import { AppData } from '../types';
import { DeviceType, DeviceTypes } from '../types/userMeasurement';
import { AB_MASTER_COOKIE_NAME } from './cookies';

/**
 * Don't forget to add A/B test names to ABTestNames.
 */
export const ABTestDefinitions: Record<ABTestNames, ABTestDefinition> = {
  PreJoinPageRedesign: {
    id: 1,
    variantCount: 2,
    defaultVariant: 1,
    userTypes: [UserTypes.Free, UserTypes.Guest],
    cacheLevel: CacheLevel.Site,
    enabledForAffiliateSessions: false,
  },
};

/**
 * Get an A/B test definition by its id.
 *
 * @param id
 */
export const findABTestDefinitionById = (id: number): ABTestDefinition | undefined => {
  return Object.values(ABTestDefinitions).find((definition) => definition.id === id);
};

export const getABVariantClientStorageKey = (abTestName: ABTestNames) => {
  return `ab${ABTestDefinitions[abTestName]?.id}v`;
};

export const getABTestNameFromId = (id: number): ABTestNames | undefined => {
  for (const [name, definition] of Object.entries(ABTestDefinitions)) {
    if (definition.id === id) {
      return name;
    }
  }

  return undefined;
};

type IsAbTestEnabledProps = {
  testId: number;
  appData?: AppData;
  /** Undefined means any. */
  deviceType?: DeviceType;
};

export const isABTestEnabled = (props: IsAbTestEnabledProps): boolean => {
  const { testId, appData, deviceType } = props;

  // if we have the A/B master cookie, all A/B tests are enabled
  // NOTE: this only works on clients, you have to do this cookie check manually on server side
  if (typeof document !== 'undefined' && document.cookie && document.cookie.includes(`${AB_MASTER_COOKIE_NAME}=`)) {
    return true;
  }

  const abTestDefinition = findABTestDefinitionById(testId);
  if (!abTestDefinition) {
    return false;
  }

  if (!appData || !appData.featureFlags.enabledABTests) {
    return false;
  }

  if (!abTestDefinition.enabledForAffiliateSessions && appData.hasAffiliateCookie) {
    return false;
  }

  if (deviceType) {
    const mobileDeviceTypes: DeviceType[] = [DeviceTypes.Mobile, DeviceTypes.Tablet];
    const propertyName = mobileDeviceTypes.includes(deviceType) ? 'mobile' : 'desktop';

    const deviceFeatureFlags = appData.featureFlags.enabledABTests[propertyName];
    if (!deviceFeatureFlags) {
      return false;
    }

    return deviceFeatureFlags.includes(testId);
  }

  return Object.entries(appData.featureFlags.enabledABTests).some(([, testIds]) => {
    return testIds.includes(testId);
  });
};
