import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { ApiClient, useAppData } from 'src/client/helpers';
import { ABTestMeasurementProps, CategoryIds, UseAnalyticsProps } from 'src/shared/types/userMeasurement';
import { getDeviceType } from '../../helpers/utils';
import { ANALYTICS_COOKIE_NAME, CSRF_TOKEN } from '../../../shared/constants/cookies';
import { getABTestNameFromId, getABVariantClientStorageKey } from '../../../shared/constants/abTests';

type UseAnalyticsABMeasurementProps = {
  abTestId: number;
  abTestVariantId?: number;
};

export const useAnalyticsABMeasurement = (props: UseAnalyticsABMeasurementProps) => {
  const { abTestId, abTestVariantId } = props;
  const { user } = useAppData();

  const abTestName = getABTestNameFromId(abTestId) as string;
  const abStorageKey = getABVariantClientStorageKey(abTestName);
  const [cookies] = useCookies([ANALYTICS_COOKIE_NAME, abStorageKey, CSRF_TOKEN]);
  const cookieAnalyticsUUID: string = cookies[ANALYTICS_COOKIE_NAME];
  const csrfToken: string = cookies[CSRF_TOKEN];
  const variantId: number = parseInt(abTestVariantId || cookies[abStorageKey], 10);

  const sendMeasurement = useCallback(
    async (useAnalyticsProps: UseAnalyticsProps) => {
      const { type, analyticsUUID, ...data } = useAnalyticsProps;

      try {
        if (!variantId || !csrfToken) {
          return;
        }

        const api = new ApiClient();
        await api.post('/api/analytics', {
          data: {
            uniqueUUID: analyticsUUID ?? cookieAnalyticsUUID,
            data: {
              abTestId,
              abTestVariantId: variantId,
              userType: user.userType,
              ...data,
            },
            type,
          },
        });
      } catch (err) {
        return err;
      }
    },
    [abTestId, user, variantId, cookieAnalyticsUUID, csrfToken],
  );

  const sendABTestMeasurement = useCallback(
    async (props: ABTestMeasurementProps) => {
      const { type, conversionType, analyticsUUID, data } = props;

      sendMeasurement({
        analyticsUUID,
        categoryId: CategoryIds.PreJoinABTests,
        conversionType,
        deviceType: getDeviceType(navigator.userAgent),
        type,
        data,
      });
    },
    [sendMeasurement],
  );

  return {
    sendABTestMeasurement,
  };
};
