import { AltPayments } from '@tovia/man-protos/dist/types/billing.types';
import { useEffect, useState } from 'react';
import { Biller } from '../../../shared/constants/billers';
import { useAppData } from '../../helpers';
import { useQueryParams } from './PreJoinPage';
import { entries } from '../../helpers/entries';

type AlternativePaymentsFilterMap = {
  [Key in keyof AltPayments]?: {
    [billerID: number]: () => boolean;
  };
};

type HookParams = {
  alternativePayments?: AltPayments;
  email: string;
  unselectActivePaymentByBillerId: (billerId: number) => void;
};

export const useFilterAlternativePayments = ({
  alternativePayments,
  email,
  unselectActivePaymentByBillerId,
}: HookParams) => {
  const { user } = useAppData();
  const { affiliateID } = useQueryParams();
  const [activeAlternativePayments, setActiveAlternativePayments] = useState<AltPayments>();

  const { isUser } = user;

  // Mimics backend behaviour (see `emailHasPlus`)
  const isCCBillFiltered = () => {
    return !isUser && email.includes('+') && !affiliateID;
  };

  const filterMap: AlternativePaymentsFilterMap = {
    creditCard: {
      [Biller.CCBill]: isCCBillFiltered,
    },
  };

  useEffect(() => {
    if (!activeAlternativePayments) {
      setActiveAlternativePayments(alternativePayments);
    }
  }, [activeAlternativePayments, alternativePayments]);

  const filterAlternativePaymentMethods = () => {
    if (!alternativePayments) {
      return;
    }

    setActiveAlternativePayments(
      entries(alternativePayments).reduce((acc, [key, value]) => {
        const subCategoryFilters = filterMap[key];
        acc[key] = value.filter((altPayment) => {
          const isFilteredFn = subCategoryFilters?.[altPayment.billerID];
          const isFiltered = isFilteredFn?.();
          if (isFiltered) {
            unselectActivePaymentByBillerId(altPayment.billerID);
          }

          return !isFiltered;
        });
        return acc;
      }, {} as AltPayments),
    );
  };

  return { activeAlternativePayments, filterAlternativePaymentMethods };
};
