import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { ApiClient, useAppData } from 'src/client/helpers';
import { apiUrls } from 'src/client/helpers/constants';
import { Biller } from 'src/shared/constants/billers';

type UserBillingData = {
  billerID?: number;
  PA?: string;
  subscriptionUUID?: string;
  extSubscriptionID?: string;
  extProdictID?: string;
};

type UseCreateOneClickArgs = {
  userBillingData: UserBillingData;
};

export const useCreateOneClickURL = (args: UseCreateOneClickArgs): string => {
  const { userBillingData } = args;
  const [iFrameURL, setIFrameURL] = useState('');
  const [cookies] = useCookies(['mamident']);
  const { site, user } = useAppData();

  useEffect(() => {
    // it is crucial to have the user defined at this point, and this hook is called inside a protected route but should the
    // oneClick registration fail and it's because a missing user mail, it might be easy to overlook this
    if (!user) {
      // eslint-disable-next-line no-console
      console.error('User is not defined, unable to create oneClick URL without user email!');
      return;
    }

    const data = {
      ...userBillingData,
      ...{
        email: user.email,
        mamIdent: cookies['mamident'],
        paramsJSON: null,
        siteUUID: site.UUID,
      },
    };

    const postOneClickData = async () => {
      const api = new ApiClient();
      try {
        const response = await api.post(apiUrls.post.createOneClickURL, { data });
        setIFrameURL(response.body.url);
      } catch (err) {
        window.dataLayer.push({
          event: 'gaEvent',
          eventCategory: 'failed_oneclick_registration',
          eventLabel: userBillingData.subscriptionUUID,
        });

        // eslint-disable-next-line no-console
        console.error('Request failed receive oneClick URL');
      }
    };

    // CCBIll and epoch are registering the users this way
    if (userBillingData.billerID === Biller.CCBill || userBillingData.billerID === Biller.Epoch) {
      postOneClickData();
    }
  }, [userBillingData, user, site, cookies]);

  return iFrameURL;
};
