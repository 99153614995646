import React from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { useRouteMatch } from 'react-router-dom';
import { useAppData, constants, useURLSearchParams } from '../helpers';

const { BILLING_TEST_ORDER_COOKIE, routerPaths, SITE_TEST_ORDER_COOKIE } = constants;
// type Props = {
//   selectedTestId?: string | number;
// };

const useTestOrder = (): string | undefined => {
  const [cookies] = useCookies([BILLING_TEST_ORDER_COOKIE, SITE_TEST_ORDER_COOKIE]);

  const purchaseGalleryMatch = !!useRouteMatch({ exact: true, path: routerPaths.purchase.gallery });
  const successPageMatch = !!useRouteMatch({ exact: true, path: routerPaths.purchase.success });

  // only use testOrder cookie if on PPS purchase page or success page
  const useTestOrderCookie = purchaseGalleryMatch || successPageMatch;

  if (cookies[BILLING_TEST_ORDER_COOKIE]) {
    return cookies[BILLING_TEST_ORDER_COOKIE];
  }

  if (useTestOrderCookie && cookies[SITE_TEST_ORDER_COOKIE]) {
    return cookies[SITE_TEST_ORDER_COOKIE];
  }

  return undefined;
};

const vwoScript = `
  window._vwo_code = window._vwo_code || (function(){
  var account_id=555654,
  settings_tolerance=4000,
  library_tolerance=10000,
  use_existing_jquery=false,
  is_spa=1,
  hide_element='',

  /* DO NOT EDIT BELOW THIS LINE */
  f=false,d=document,code={use_existing_jquery:function(){return use_existing_jquery;},library_tolerance:function(){return library_tolerance;},finish:function(){if(!f){f=true;var a=d.getElementById('_vis_opt_path_hides');if(a)a.parentNode.removeChild(a);}},finished:function(){return f;},load:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){
  window.settings_timer=setTimeout(function () {_vwo_code.finish() },settings_tolerance);var a=d.createElement('style'),b=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',h=d.getElementsByTagName('head')[0];a.setAttribute('id','_vis_opt_path_hides');a.setAttribute('type','text/css');if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));h.appendChild(a);this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+(+is_spa)+'&r='+Math.random());return settings_timer; }};window._vwo_settings_timer = code.init(); return code; }());
`;

export const HTMLHead = () => {
  const { site, cdnUrl, testId } = useAppData();
  const searchParams = useURLSearchParams();
  const [cookieValue] = useCookies([BILLING_TEST_ORDER_COOKIE, SITE_TEST_ORDER_COOKIE]);

  const cookies: Record<string, string> = {
    [BILLING_TEST_ORDER_COOKIE]: cookieValue[BILLING_TEST_ORDER_COOKIE] || '',
    [SITE_TEST_ORDER_COOKIE]: cookieValue[SITE_TEST_ORDER_COOKIE] || '',
  };

  const testOrder = useTestOrder();
  const affiliateID = searchParams.get('PA') || (cookies[`${site?.abbreviation}_PA`] as string);
  const selectedTestId = !affiliateID && !searchParams.get('promoCode') ? testOrder || testId : undefined;

  return (
    <Helmet>
      <title>{site ? `Join ${site.name} Now!` : 'Join Now!'}</title>
      {site && <link rel="icon" href={`${cdnUrl}/favicon/${site.abbreviation}.ico`} />}
      {selectedTestId && <script type="text/javascript">{`var testId = ${selectedTestId};`}</script>}
      {selectedTestId && <script type="text/javascript">{vwoScript}</script>}
      <script>
        {`(function (t, a) { window.ata = a, a.init = function (t, r) { a._token = t, r && (a._initParams = r); }, a._calls = []; for (let r = ['track'], c = 0; c < r.length; c += 1) { const i = r[c]; a[i] = (function (t) { return function () { a._calls.push([t, Array.prototype.slice.call(arguments, 0)]); }; }(i)); } const script = t.createElement('script'); script.type = 'text/javascript'; script.async = !0; script.src = 'https://ata-cdn.metart.network/bundle.js'; const scriptTag = t.getElementsByTagName('script')[0]; scriptTag.parentNode.insertBefore(script, scriptTag); }(document, window.ata || []))`}
      </script>
      <script>{`ata.init('${site.networkUUID}', { skipRawHit: true })`}</script>
    </Helmet>
  );
};
