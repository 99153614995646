import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AltPayment, AltPayments } from '@tovia/man-protos/dist/types/billing.types';
import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { entries } from 'src/client/helpers/entries';
import { TABLET_LANDSCAPE, TABLET_PORTRAIT } from 'src/client/helpers/mediaQueries';
import { AlternativePaymentOption } from './AlternativePaymentOption';

type Props = {
  altPayments?: AltPayments;
  cdnUrl?: string;
  country?: string;
  selectAltPayment: (altPayment: AltPayment) => void;
  selectedAltPayment?: AltPayment;
  setShowAlternativePaymentPanel: (show: boolean) => void;
};

const creditCards = ['visa', 'mastercard', 'discover', 'jcb', 'diners_club', 'maestro'];

export const AlternativePayments = (props: Props) => {
  const { altPayments, country, cdnUrl } = props;

  const makeIconComponent = (icon: string) => (
    <CreditCardIcon key={icon} src={`${cdnUrl}/payment/small/${icon}.svg`} alt={icon} />
  );

  const titleStrings: Record<keyof AltPayments, ReactNode> = {
    country: <FormattedMessage id="alternative.payments.titles.country" values={{ country }} />,
    creditCard: (
      <>
        <FormattedMessage id="alternative.payments.titles.credit_card" />
        <CreditCardIcons>{creditCards.map(makeIconComponent)}</CreditCardIcons>
      </>
    ),
    misc: <FormattedMessage id="alternative.payments.titles.misc" />,
    international: <FormattedMessage id="alternative.payments.titles.international" />,
  };

  if (!altPayments) return null;

  return (
    <PaymentsPanel className="alternative-payments">
      <CloseButton onClick={() => props.setShowAlternativePaymentPanel(false)}>
        <FontAwesomeIcon icon={faTimes} />
      </CloseButton>
      <Line />
      {entries(altPayments).map(([paymentGroup, payments]) => {
        if (payments.length === 0) return null;
        return (
          <React.Fragment key={paymentGroup}>
            <PaymentRowTitle>{titleStrings[paymentGroup]}</PaymentRowTitle>
            <PaymentRow>
              {payments.map((payment, id) => (
                <AlternativePaymentOption
                  cdnUrl={props.cdnUrl}
                  key={id}
                  payment={payment}
                  selectAltPayment={props.selectAltPayment}
                  selectedAltPayment={props.selectedAltPayment}
                  paymentGroup={paymentGroup}
                />
              ))}
            </PaymentRow>
          </React.Fragment>
        );
      })}
    </PaymentsPanel>
  );
};

const Line = styled.hr`
  margin: 0px;
  border: 1px solid #e6e6e6;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaymentsPanel = styled.div`
  position: relative;
  border: 1px solid lightgray;
  border-radius: 4px;
  margin-top: 10px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.19);
  padding: 36px;
`;

const PaymentRow = styled.div`
  display: grid;
  grid-auto-rows: minmax(48px, auto);
  grid-gap: 20px;
  grid-template-columns: 1fr;

  @media ${TABLET_PORTRAIT} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${TABLET_LANDSCAPE} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const PaymentRowTitle = styled.h2`
  font-weight: 400;
  font-size: 16px;
  flex-flow: row wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;

  @media ${TABLET_PORTRAIT} {
    font-size: inherit;
  }
`;

const CreditCardIcons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const CreditCardIcon = styled.img`
  width: 26px;
`;
