import { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { useAppData } from 'src/client/helpers';

export const useSuccessPageQueryParams = () => {
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { site } = useAppData();
  const [cookies] = useCookies([`${site?.abbreviation}_PA`, 'mam']);

  const quality = queryParams.get('quality') || queryParams.get('x_quality');
  const galleryUUID =
    queryParams.get('galleryUUID') ||
    queryParams.get('x_galleryUUID') ||
    queryParams.get('objectUUID') ||
    queryParams.get('x_object_uuid');
  const cartItemUUID = queryParams.get('cartItemUUID') || queryParams.get('x_cart_item_uuid');
  const purchaseType = queryParams.get('x_type') || queryParams.get('type');
  const affiliateID = queryParams.get('PA') || cookies[`${site.abbreviation}_PA`];
  const extSubscriptionID = queryParams.get('subscriptionID') || queryParams.get('member_id');
  const paymentType = queryParams.get('payment_type');
  const answer = queryParams.get('ans');
  const successResponse = queryParams.get('success');
  const extProductID = queryParams.get('x_product_uuid');
  const mamPartnerID = useMemo(() => (cookies.mam ? cookies.mam.split('.')[0] : null), [cookies.mam]);

  return {
    affiliateID,
    answer,
    cartItemUUID,
    extProductID,
    extSubscriptionID,
    galleryUUID,
    mamPartnerID,
    paymentType,
    purchaseType,
    quality,
    successResponse,
  };
};
